<template>
	<page>
		<module enable-flex>
			<c-table ref="table">
				<c-table-column
					label="状态"
					width="80"
				>
					<template v-slot="{data}">
						<span v-if="data.state == 1" style="color: #f00">未读</span>
						<span v-else>已读</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="标题"
					name="title"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="内容"
					name="content"
					width="600"
				></c-table-column>
				
				<c-table-column
					label="日期"
					name="create_date"
					width="160"
				></c-table-column>
			</c-table>
		</module>
		
		<c-dialog ref="detail" title="消息通知详情" fadein width="850" height="670" :button="false">
			<h1 class="message-title">{{messageContent.title}}</h1>
			<div v-html="messageContent.content" class="message-content"></div>
		</c-dialog>
	</page>
</template>

<script>
	export default {
		data() {
			return {
				messageContent: {}
			}
		},
		
		mounted() {
			this.$refs.table.load({
				url: '/sysnotice/list'
			});
			
			this.request({
				url: '/sysnotice/read'
			});
			
			this.$store.dispatch('updateMessageTotal');
		},
		
		methods: {
			clickHandle(item) {
				this.messageContent = item;
				this.$refs.detail.open();
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.message {
		&-title {
			padding: 20px 32px;
			line-height: 28px;
			font-size: 20px;
			text-align: center;
			color: #000;
			background: #F6F7F8;
		}
		
		&-content {
			padding: 32px;
			line-height: 22px;
			font-size: 14px;
		}
	}
</style>
