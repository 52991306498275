<template>
	<app tabbar>
		<scroll-list-m ref="list">
			<template v-slot="{data}">
				<div class="message" :class="{'z-new': data.state == 1}">
					<span>{{data.create_date}}</span>
					<div>
						<h1>{{data.title}}</h1>
						<p>{{data.content}}</p>
					</div>
				</div>
			</template>
		</scroll-list-m>
	</app>
</template>

<script>
	export default {
		mounted() {
			this.$refs.list.load({
				url: '/sysnotice/list'
			});
			
			this.request({
				url: '/sysnotice/read'
			});
		},
		
		methods: {
			clickHandle(item) {
				this.messageContent = item;
				this.$refs.detail.open();
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.message {
		&.z-new {
			h1 {
				position: relative;
				display: inline-block;
				color: #363A44;
				
				&:after {
					content: '';
					position: absolute;
					right: -.24rem;
					top: .05rem;
					width: .12rem;
					height: .12rem;
					border-radius: 100%;
					background: #f00;
				}
			}
		}
		
		span {
			display: block;
			padding-top: .2rem;
			line-height: .8rem;
			font-size: .26rem;
			text-align: center;
			color: #B3B5B9;
		}
		
		div {
			border-radius: .16rem;
			padding: .3rem .34rem;
			margin: 0 .24rem;
			background: #fff;
			
			h1 {
				margin-bottom: .2rem;
				line-height: .5rem;
				font-size: .32rem;
				color: #686B73;
			}
			
			p {
				line-height: .44rem;
				font-size: .28rem;
				color: #888;
			}
		}
	}
</style>
